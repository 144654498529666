import React from 'react';

interface Props {
}

const CreditCard: React.FC<Props> = (props) => {
    return (
        <div>
            <svg width="108" height="91" viewBox="0 0 108 91" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <rect width="108" height="91" fill="url(#pattern0_625_7693)" />
                <defs>
                    <pattern id="pattern0_625_7693" patternContentUnits="objectBoundingBox" width="1" height="1">
                        <use xlinkHref="#image0_625_7693" transform="matrix(0.015625 0 0 0.018544 0 -0.0934066)" />
                    </pattern>
                    <image id="image0_625_7693" width="64" height="64" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABAEAQAAABQ8GUWAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAAAqo0jMgAAAAlwSFlzAAAAYAAAAGAA8GtCzwAAAAd0SU1FB+cGDBEoE8YE7bEAAALlSURBVHja7dpRSBNhAAfw/3ebua162EQhwoEPotCTSGDu0sACIXvLkxJ6UBHsIWNCQvZiKAxDwZdENyRzV3D2JmS+GJLuKRQfQjEQ2ggi2Y0jbXDj9vVgiUXCxrr7lvt+MPhgu7v/92ffcXcbwHEcx3EcxxUmAgCTky6X63JfH2n1+XDeZmMdylSfDYMYKytFj0dHJSmZJIri8eieSARfqqpYZ7PUq60t0lpfT8LnQiE86ewEALRGo7i7u8s6m6melpZizusFADIUCpHwuKqixO2m7cvLxXNNTZJkGKwzmklRbDZ9fWkJFxoaMKKqAkrcbgAg8+vrJ33yACBJhoFLa2sAgAcej3D4TjulrMNZ5shchVz2cxLwAlgHYK3gC7D/GtB7ojjbHAiwDmQF+kYUyc8xCYcL6Oz/FwW/BHgBrAOwxgtgHYC1gi/g8DoAp6enyffhYdaBrEBdAwPY7+j4vYA7mtb+bWeHdTgrhM9qGiYOxgW/BHgBrAOwxgtgHYA1ezYffl7h9dqG7Fltkynhdjq9hWh0kKTTeVnA7LtAgHzq7zfr5tmQgcp4MAh0d1tZQMZLQGg8+DHBVFMWHOMPGX8Dil729OgLi4vkWnGxGUHSqVTKVj8/jw95WoDUpmnAzAyeWRswbwoAAEVxOlMphyObbYrs+/tSm66znuhxMj4HzMb9fv3q3h6lqprNS6/QNPlMSwvrieZcANkWRbwWsr9u2HY46Pu6OtYTPU7GS8Cm9fam5Y2N9EOnM5sDCNFEgi5OTLCeaM4F3GqOxYDBQSywjvxvFfylMC+AdQDWeAGHI5mQHPbzfzkyVwHxRAIA6I2aGkU54f8RBPCW2u24UlsLABhRVSJXB4P0UVcXAGAqFkPl16+sQ5rqY1kZusvLAQDxYJDIsttN5yIR3KyuZp3NSuT+5ibGfT4CHNzk6Mt+P3khivSiOU988sb1VArbq6unGsfGJCmZZB2H4ziO4ziOY+UHljkE4sz77kIAAAAASUVORK5CYII=" />
                </defs>
            </svg>

        </div>
    );
};

export default CreditCard;
