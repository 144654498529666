import { useCallback, useEffect, useMemo } from "react";
import useSWR from "swr";

export default function useProductSelected({ name }: { name: string }) {
  const uri = useMemo(
    () => "/api/tienda/products?joins=[]".concat(`&filters=[]`),
    [name]
  );
  const { data } = useSWR(
    uri,
    async (url) => url && fetch(url).then((res) => res.json())
  );

  return { products: data?.data ?? [] };
}
