"use client";

import React, { use, useEffect } from "react";
import useProductSelected from "./hooks";
import HorizontalProductList from "../horizontal-product-list";
import { useRouter } from "next/navigation";

interface ProductSelectedProps {
  title?: string;
  subtitle?: string;
  name: string;
}

const ProductSelected: React.FC<ProductSelectedProps> = (props) => {
  const router = useRouter();
  const { products } = useProductSelected({ name: props.name });

  useEffect(() => {
    if (products) console.log(products);
  }, []);

  return (
    <div className="py-5">
      <HorizontalProductList
        callback={(p) => router.push(`/p/${p.id}`)}
        data={products}
        title={props.title ?? props.name}
        subtitle={props.subtitle ?? ""}
      />
    </div>
  );
};

export default ProductSelected;
