import HorizontalSlider from "@/lib/components/horizontal-slider";
import ProductDetail from "@/lib/components/product-detail";
import React from "react";

import { League_Gothic } from "next/font/google";
import classNames from "classnames";

const gothic = League_Gothic({ subsets: ["latin"] });

interface HorizontalProductListProps {
  data: any[];
  title: string;
  subtitle: string;
  callback?: (product: any) => void;
}

const HorizontalProductList: React.FC<HorizontalProductListProps> = ({
  data,
  title,
  callback,
  subtitle,
}) => {
  return (
    <div className="w-full">
      <div
        className={classNames(
          gothic.className,
          "flex flex-col items-center justify-center text-gray py-5"
        )}
      >
        <h2 className="text-3xl md:text-6xl uppercase tracking-[.25rem]">
          {title}
        </h2>
        <h3 className="">{subtitle}</h3>
      </div>
      <div>
        <HorizontalSlider>
          <div className="flex flex-row items-start gap-5 w-full py-5">
            {data.map((p, index) => {
              let product = {
                id: p?.id,
                name: p?.name,
                category: p?.line?.name,
                price: p?.stocks?.at(0)?.price,
                old: p?.stocks?.at(0)?.price,
                images: [p?.thumbnail].concat(p?.gallery),
              };
              return (
                <ProductDetail
                  key={index}
                  callback={callback}
                  className="md:w-[250px] w-fit"
                  product={product}
                />
              );
            })}
          </div>
        </HorizontalSlider>
      </div>
    </div>
  );
};

export default HorizontalProductList;
