"use client";
import Image from "next/image";
import React, { FC, use, useEffect, useState } from "react";

export interface ImageSliderProps {
  images: string[];
  children?: React.ReactNode;
  dimensions?: { width: number; height: number };
  priority?: boolean;
}

const ImageSlider: FC<ImageSliderProps> = ({
  images,
  children,
  dimensions,
  priority,
}) => {
  const [current, setCurrent] = useState(0);
  const length = images?.length ?? 0;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const goTo = (index: number) => setCurrent(index);

  const canShowImage = Array.isArray(images) && images.length > 0;

  return (
    <div className="flex flex-col justify-center items-stretch w-full h-full overflow-hidden">
      <div className="flex-1">
        {canShowImage &&
          images?.map((image, index) => (
            <div
              className={
                index === current
                  ? "opacity-100 transition-opacity duration-1000"
                  : "opacity-0 transition-opacity duration-1000"
              }
              key={index}
            >
              {index === current && image?.includes("http") && (
                <Image
                  priority={priority}
                  src={image}
                  width={dimensions?.width ?? 100}
                  height={dimensions?.height ?? 200}
                  alt="carousel"
                  className="w-full h-auto object-cover"
                />
              )}
            </div>
          ))}
      </div>
      {children && <div className="p-2">{children}</div>}
      {canShowImage && (
        <div className="flex flex-row items-center justify-center gap-2 p-2">
          {images?.map((_, index) => (
            <button
              key={index}
              className=" bg-[#D68D9F] w-[10px] h-[10px] rounded-full shadow-lg"
              onClick={() => goTo(index)}
            ></button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
