"use client"
import React from 'react';
import Truck from '../icons/truck';
import Link from 'next/link';
import CreditCard from '../icons/credit-card';
import BoxCircle from '../icons/box-circle';

interface Props {
    // Define your component's props here
}

const QuickInfos: React.FC<Props> = (props) => {

    const infos = [
        { Icon: Truck, description: "+ info", title: "Envíos a todo el país", href: "/" },
        { Icon: CreditCard, description: "+ conocé todos los medios de pago", title: "Pagos seguros", href: "/" },
        { Icon: BoxCircle, description: "+ conocé nuestras políticas de cambio", title: "Cambios y devoluciones", href: "/" },
    ]

    return (
        <div className='py-20 '>
            <ul className='grid grid-cols-1 md:grid-cols-3 gap-20 max-w-[70%] m-auto'>
                {infos.map((item, index) => (
                    <li key={index} className=' '>
                        <Link href={item.href} className='flex flex-col items-center justify-center gap-5 text-center'>
                            <div><item.Icon /></div>
                            <div className='font-bold text-gray'>{item.title}</div>
                            <div className='text-gray'>
                                {item.description}
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default QuickInfos;
