"use client"
import React from 'react';
import { League_Gothic } from 'next/font/google';
import useProductLines from './hooks';
import classNames from 'classnames';
import ImageOverlay from '@/lib/components/image-overlay';

const gothic = League_Gothic({ subsets: ['latin'] })

const ProductLines: React.FC = () => {
    const { lines } = useProductLines();
    return (
        <div className={classNames("mb-20")}>
            <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
                {lines.map((line: any, index: number) => (
                    <ImageOverlay
                        key={index}
                        href={"/shop/".concat(String(line.name).toLowerCase())}
                        title={line?.name}
                        imageSrc={"https://s3.us-east-1.amazonaws.com/media.crayonropadechicos.com.ar/uploads/media/image/1712947892625.jpeg"}
                    />
                ))}
            </div>
        </div>
    );
};

export default ProductLines;
