import { useEffect } from 'react';
import useSWR from 'swr';

export default function useProductLines() {
    const { data } = useSWR("/api/tienda/lines", async (url) => fetch(url).then(res => res.json()));
    const { data: _lines } = data ?? {}

    const lines = ["Baby Shower", "Bebe", "Minis", "Teen"].map(i => ({ name: i }))

    return { lines: lines ?? [] };
}

