"use client";
import React, { FC, ReactNode } from "react";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import ImageSlider from "../image-slider";
import Link from "next/link";
import classNames from "classnames";

type ProductType = {
  name: string;
  category?: string;
  price: number;
  old?: number;
  images: string[];
};

type ProductDetailProps = {
  product: ProductType;
  children?: ReactNode;
  className?: string;
  sales?: boolean;
  dimensions?: { width: number; height: number };
  callback?: (product: ProductType) => void;
};

const ProductDetail: FC<ProductDetailProps> = ({
  product,
  className,
  sales,
  dimensions,
  callback,
  children,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center bg-white shadow-md rounded-lg p-4",
        className
      )}
    >
      <div className="text-center mb-5">
        <h3 className="text-gray text-lg font-bold">{product.name}</h3>
        <p className="text-gray-light text-sm">{product.category}</p>
      </div>
      <div className="flex justify-between w-full">
        {sales && (
          <div className="bg-dark text-white text-xs font-bold py-1 px-2">
            SALE
          </div>
        )}
        <button type="button" className="text-gray-400 hover:text-red-500">
          <Icon icon={faHeart}></Icon>
        </button>
      </div>
      <div>
        <ImageSlider images={product.images} dimensions={dimensions} />
      </div>
      <div className="flex flex-row items-center justify-center gap-2">
        {product.old && (
          <p className="text-gray text-sm line-through">{product.old}</p>
        )}
        <p className="text-dark text-lg font-bold">{product.price}</p>
      </div>
      <div className="p-1 mt-4 ">
        <button
          type="button"
          onClick={() => callback && callback(product)}
          className="bg-success text-sm text-white py-2 px-10 rounded-full uppercase w-auto "
        >
          Comprar
        </button>
      </div>
    </div>
  );
};

export const ProductDetailSimple: FC<ProductDetailProps & { href: string }> = ({
  className,
  product,
  href,
  sales,
  dimensions,
  children,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center bg-white shadow-md rounded-lg p-4",
        className
      )}
    >
      <div className="flex justify-between w-full">
        {sales && (
          <div className="bg-dark text-white text-xs font-bold py-1 px-2">
            SALE
          </div>
        )}
        <button className="text-gray-400 hover:text-red-500">
          <Icon icon={faHeart}></Icon>
        </button>
      </div>
      <div>
        <Link href={href}>
          <ImageSlider images={product.images} dimensions={dimensions}>
            <div className="text-center mb-2">
              <h3 className="text-gray text-lg font-bold">{product.name}</h3>
            </div>
            <div className="flex flex-row items-center justify-center gap-2">
              {product.old && (
                <p className="text-gray text-sm line-through">{product.old}</p>
              )}
              <p className="text-dark text-lg font-bold">${product.price}</p>
            </div>
          </ImageSlider>
        </Link>
      </div>
    </div>
  );
};

export default ProductDetail;
